<template>
  <div class="gType-wrap">
   <div @click="single(item)" :class="[value===item.id?'Select':'']" v-for="(item,index) in list" :key="index">
      {{item.title}}
   </div>
   <div style="height: 0;border:none;margin: 0;"></div>
   <div style="height: 0;border:none;margin: 0;"></div>
   <div style="height: 0;border:none;margin: 0;"></div>
  </div>
</template>

<script>
export default{
  name:'gType',
  props:['list','value'],
  model:{
    prop:'value',
    event:'change'
  },
  methods:{
    single(item){
      this.$emit('change',item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.gType-wrap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  >div{
    width: 161px;
    height: 62px;
    line-height: 62px;
    background: #F7F7F7;
    border-radius: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #464646;
    border: 2px solid #F7F7F7;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    padding: 0 5px;
  }
  .Select{
    background: #F4F8FF;
    border: 2px solid #2C6BF8;
    color: #2C6BF8
  }
}
</style>
