<template>
  <div class="timeHorizon-wrap">
    <van-popover
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
      placement="top"
    >
      <template #reference>
        <van-field v-model="title" class="readonly-field" maxlength="10" type="text" placeholder="请选择时间" readonly  right-icon="arrow-down"/>
      </template>
    </van-popover>
  </div>
</template>

<script>
import util from '@/utils/util.js'
export default{
  name:'timeHorizon',
  props:['value'],
  model:{
    prop:'value',
    event:'change'
  },
  data(){
    return{
      title:'',
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '不限',value:-1}, { text: '一个月内',value:30 }, { text: '两个月内',value:60 },{text: '三个月内',value:90 },{text: '半年内',value:180},{text: '一年内',value:360}]
    }
  },
  methods:{
    onSelect(action) {
      this.title=action.text
      if(action.value===-1){
        this.$emit('change')
      }else{
        var date = new Date();
        this.$emit('change',util.getDateRange(date, action.value, true)[0])
      }
    },
    reset(){
      this.title=''
    }
  }
}
</script>

<style lang="scss" scoped>
.timeHorizon-wrap{
  .readonly-field{
    width: 219px;
    line-height: 59px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 0 10px;
  }
}

</style>
