<template>
  <div class="memberScreening-wrap">
    <NavBar />
    <van-form @submit="onSubmit">
      <span class="Tips">请选择需要筛选条件</span>
      <h4>会员卡类型</h4>
      <van-field name="member_card_type" :value="formData.member_card_type">
        <template #input>
          <gTypes ref="gTypes" v-model="formData.member_card_type" :list="cardTypeList" />
        </template>
      </van-field>
      <h4>会员性别</h4>
      <van-field name="member_sex" :value="formData.member_sex">
        <template #input>
          <gType v-model="formData.member_sex" :list="maleList" />
        </template>
      </van-field>
      <h4>会员卡状态</h4>
      <van-field name="member_card_state" :value="formData.member_card_state">
        <template #input>
          <gType v-model="formData.member_card_state" :list="cardState" />
        </template>
      </van-field>
      <h4>来店频次</h4>
      <van-field class="field" v-model="formData.member_out_day" maxlength="4" name="member_out_day" type="digit" placeholder="请输入">
          <template #button>天未到店</template>
      </van-field>
      <h4>充值金额</h4>
     <div class="fieldBox">

      <van-field name="member_pay_money_time" :value="formData.member_pay_money_time">
          <template #input>
            <timeHorizon ref="time1" v-model="formData.member_pay_money_time"/>
          </template>
        </van-field>

       <van-field name="member_pay_money" :value="JSON.stringify(formData.member_pay_money)">
          <template #input>
            <range v-model="formData.member_pay_money" />
          </template>
        </van-field>

      </div>
      <h4>更多</h4>
      <gType v-model="moreid" :list="moreList" />

      <div class="fieldBox top">

       <van-field v-if="moreid!==2" name="member_consume_time" :value="formData.member_consume_time">
           <template #input>
             <timeHorizon ref="time2" v-model="formData.member_consume_time"/>
           </template>
         </van-field>

        <van-field v-if="moreid===0" name="member_consume_num" :value="JSON.stringify(formData.member_consume_num)">
           <template #input>
             <range v-model="formData.member_consume_num" unit='次' />
           </template>
         </van-field>

         <van-field v-if="moreid===1" name="member_consume_money" :value="JSON.stringify(formData.member_consume_money)">
            <template #input>
              <range v-model="formData.member_consume_money" />
            </template>
         </van-field>

         <van-field v-if="moreid===2" name="member_card_money" :value="JSON.stringify(formData.member_card_money)">
            <template #input>
              <range v-model="formData.member_card_money" />
            </template>
         </van-field>

       </div>

      <div class="operatingButton">
        <van-button round plain type="primary" native-type="button" @click="reset">重置</van-button>
        <van-button round block type="info" native-type="submit">保存并选择短信模板</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import table from '@/api/table';
import NavBar from '@/components/NavBar';
import gTypes from './components/from/gTypes';
import gType from './components/from/gType';
import range from './components/from/range';
import timeHorizon from './components/from/timeHorizon';

export default {
  name: 'memberScreening',
  components: { NavBar, gTypes, gType, range, timeHorizon },
  data() {
    return {
      cardTypeList: [],
      maleList:[
        {id:2,title:'全部'},
        {id:1,title:'男'},
        {id:0,title:'女'}
      ],
      cardState:[
        {id:-1,title:'全部'},
        {id:0,title:'正常'},
        {id:1,title:'锁定'}
      ],
      moreList:[
        {id:0,title:'按消费次数'},
        {id:1,title:'按消费金额'},
        {id:2,title:'按会员余额'}
      ],
      moreid:0,
      formData: {
        member_card_type:'',
        member_sex:2,
        member_card_state:-1,
        member_out_day:'',
        member_pay_money_time:'',//充值的时间范围
        member_pay_money:['', ''],
        member_consume_time:'',//更多的时间范围
        member_consume_num:['', ''],
        member_consume_money:['', ''],
        member_card_money:['','']
      }
    };
  },
  mounted() {
    this.memberCardType()
  },
  methods: {
    ...mapMutations(['updateMemberScreening']),
    // 提交保存
    onSubmit(values) {
      delete values.undefined
      console.log('submit', values)
      // 表单数据处理 start
      const newObj = JSON.parse(JSON.stringify(values))
      // newObj.member_pay_money_time=JSON.parse(newObj.member_pay_money_time).filter(item=>{return item})
      // newObj.member_pay_money_time=JSON.parse(newObj.member_pay_money_time)[0]
      // newObj.member_consume_time=JSON.parse(newObj.member_consume_time).filter(item=>{return item})
      // newObj.member_consume_time=JSON.parse(newObj.member_consume_time)[0]

      newObj.member_pay_money=this.numFormat(newObj.member_pay_money)
      // console.log(newObj.member_pay_money)
      if(newObj.member_consume_num){
        newObj.member_consume_num=this.numFormat(newObj.member_consume_num)
      } else if(newObj.member_consume_money){
        newObj.member_consume_money=this.numFormat(newObj.member_consume_money)
      } else if(newObj.member_card_money){
        newObj.member_card_money=this.numFormat(newObj.member_card_money)
      }

      if(newObj.member_out_day){
        newObj.member_out_day=parseInt(newObj.member_out_day)
      }else{
        newObj.member_out_day=0
      }
      // 表单数据处理 end
      console.log('newObj', newObj)
      this.updateMemberScreening(newObj)
      this.$router.push('marketingSMS')
    },
    // 重置
    reset(){
      this.formData={
        member_card_type:'',
        member_sex:2,
        member_card_state:-1,
        member_out_day:'',
        member_pay_money_time:'',//充值的时间范围
        member_pay_money:['', ''],
        member_consume_time:'',//更多的时间范围
        member_consume_num:['', ''],
        member_consume_money:['', ''],
        member_card_money:['','']
      }
      this.moreid=0
      this.$refs.gTypes.reset()
      this.$refs.time1.reset()
      this.$refs.time2.reset()
    },
    // 格式化
    numFormat(data){
      // console.log(data)
      let list=JSON.parse(data).map(item=>{if(item){return parseFloat(item)}else{return 0}})
      // console.log(list)
      if(list[0]===0&&list[1]===0){
        list=[]
      }else if(list[1]===0){
        list.pop()
      }
      return list
    },
    // 获取会员类型
    memberCardType() {
      table.memberCardType().then(response => {
        // response.data.list.unshift({id: 0, card_type_name: "全部"})
        this.cardTypeList=response.data.list
        this.$refs.gTypes.close(response.data.list)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.memberScreening-wrap {
  .van-form{
    padding: 40px 27px 200px;
    margin-top: 20px;
    background: #fff;
    .van-cell::after {border-bottom:none}
    .Tips{
      font-size: 28px;
      font-weight: 500;
      color: #666666;
    }
    h4{
      margin: 45px 0 28px;
      font-size: 28px;
      font-weight: bold;
      color: #171719;
    }
    /deep/.field{
      input{
        padding-left: 5px;
        height: 59px;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
      }
    }
    .fieldBox{
      display: flex;
      .van-cell:nth-child(1){
        flex:1
      }
      .van-cell:nth-child(2){
        flex:2
      }
    }
    .top{
      padding-top: 22px;
    }
    .operatingButton{
      padding: 0 30px;
      background: #fff;
      display: flex;
      align-items: center;
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow:0 -2px 30px -1px #6E7370;
      height: 137px;
      >button:nth-child(1){
        width: 200px;
        border: 1px solid #8D8D8D;
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
      }
      >button:nth-child(2){
        background: #2C6BF8;
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}
</style>
