<template>
  <div class="range-wrap">
   <van-field class="field" v-model="value[0]" maxlength="6" type="digit" placeholder="请输入">
        <template #button>至</template>
    </van-field>
    <van-field class="field" v-model="value[1]" maxlength="6" type="digit" placeholder="请输入">
        <template #button>{{unit?unit:'元'}}</template>
    </van-field>
  </div>
</template>

<script>
export default{
  name:'range',
  props:['value','unit'],
  model:{
    prop:'value',
    event:'change'
  }
}
</script>

<style lang="scss" scoped>
.range-wrap{
  .van-cell::after {border-bottom:none}
  display: flex;
  /deep/.field{
    input{
      width: 180px;
    }
  }
  .field:nth-child(1){
    padding-right: 10px;
  }
}
</style>
