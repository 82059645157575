<template>
  <div class="gTypes-wrap">
   <div :class="{'Select':show}" @click="checkAll">全部</div>
   <div @click="single(item)" :class="[choose(item.id)]" v-for="(item,index) in listData" :key="index">
      {{item.card_type_name}}
   </div>
   <div v-show="list.length>6&&listData.length<8" @click="open" class="open">展开<van-icon name="arrow-down" /></div>
   <div v-show="list.length>6&&listData.length>6" @click="close(list)" class="open">收起<van-icon name="arrow-up" /></div>
   <div style="height: 0;border:none;margin: 0;"></div>
   <div style="height: 0;border:none;margin: 0;"></div>
   <div style="height: 0;border:none;margin: 0;"></div>
  </div>
</template>

<script>
export default{
  name:'gTypes',
  props:['list','value'],
  model:{
    prop:'value',
    event:'change'
  },
  data(){
    return{
      show:true,
      checked:[],
      listData:[]
    }
  },
  watch:{
    value(newVal){
      this.checked=newVal.split(",")
    }
  },
  computed:{
    choose() {
        return function(value){
          let a=''
          this.checked.forEach((x,index)=>{if(x===value){a="Select"}})
          return a
        }
    }
  },
  methods:{
    // 全选/全不选
    checkAll(){
      this.show=!this.show
      if(this.show){
        this.checked=[]
        this.$emit('change',this.checked.toString())
      }
      // if(this.show){this.checked=this.list.map(item=>{return item.id })}else{this.checked=[]}
    },
    single(item){
      let bool=true
      this.checked.forEach((x,index)=>{
        if(x===item.id){
          this.checked.splice(index, 1)
          bool=false
        }
      })
      if(bool) this.checked.push(item.id)
      if(this.checked.length===this.list.length){
        this.show=true
      }else{
        this.show=false
      }
      this.$emit('change',this.checked.toString())
    },
    open(){this.listData=this.list},
    close(list){this.listData=list.filter(function(item,index){return index<6})},
    reset(){this.show=true;this.checked=[]}
  }
}
</script>

<style lang="scss" scoped>
.gTypes-wrap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  >div{
    width: 161px;
    height: 62px;
    line-height: 62px;
    background: #F7F7F7;
    border-radius: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #464646;
    border: 2px solid #F7F7F7;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    padding: 0 5px;
  }
  .Select{
    background: #F4F8FF;
    border: 2px solid #2C6BF8;
    color: #2C6BF8
  }
  .open{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    font-weight: 500;
    color: #999999;
  }
}
</style>
